import request from '@/utils/request'


// 查询订单-退款订单列表
export function listRefund(query) {
  return request({
    url: '/order/refund/list',
    method: 'get',
    params: query
  })
}

// 查询订单-退款订单分页
export function pageRefund(query) {
  return request({
    url: '/order/refund/page',
    method: 'get',
    params: query
  })
}

// 查询订单-退款订单详细
export function getRefund(data) {
  return request({
    url: '/order/refund/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-退款订单
export function addRefund(data) {
  return request({
    url: '/order/refund/add',
    method: 'post',
    data: data
  })
}

// 修改订单-退款订单
export function updateRefund(data) {
  return request({
    url: '/order/refund/edit',
    method: 'post',
    data: data
  })
}

// 删除订单-退款订单
export function delRefund(data) {
  return request({
    url: '/order/refund/delete',
    method: 'post',
    data: data
  })
}
